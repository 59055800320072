<template>
  <content-wrapper :title="labels.LBL_BANNERS">
    <template slot="actions">
      <el-button size="small" type="success" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_ADD }}
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="bannerList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column :label="labels.LBL_PHOTO" width="80">
        <template slot-scope="scope">
          <el-avatar :size="50" shape="square" :src="scope.row.image_url">
            <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
            />
          </el-avatar>
        </template>
      </el-table-column>

      <el-table-column prop="title" :label="labels.LBL_TITLE">
      </el-table-column>
      <el-table-column :label="labels.LBL_CREATED">
        <template slot-scope="scope">
          {{ scope.row.created_at | readableDate }}
        </template>
      </el-table-column>

      <el-table-column>
        <template slot="header"> {{ labels.LBL_STATUS }} </template>
        <template slot-scope="scope">
          <el-tag
            size="mini"
            :type="scope.row.is_published ? 'success' : 'info'"
            v-text="
              scope.row.is_published
                ? labels.LBL_PUBLISHED
                : labels.LBL_UNPUBLISHED
            "
          >
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="labels.LBL_TYPE_2_SEARCH"
          />
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          ></el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "Banners",

    components: { ContentWrapper },

    data() {
      return {
        labels,

        search: "",
        params: {
          page: 1,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        banners: (state) => state.banner.banners,
      }),

      bannerList() {
        return this.banners && this.banners.data;
      },

      pagination() {
        return this.banners && this.banners.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadAllBanners();
        },
      },
    },

    methods: {
      loadAllBanners() {
        this.$store.dispatch("banner/getAllBanners", this.params);
      },

      handleSearch(search) {
        this.$store.dispatch("banner/getAllBanners", {
          ...this.params,
          search,
        });
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleAdd() {
        this.$router.push({ name: "Add Banner" });
      },

      handleEdit({ id }) {
        this.$router.push({ name: "Edit Banner", params: { bannerId: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("banner/deleteBanner", id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
